import React from "react";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";

import PlayBtn from "../../../../assets/images/play-btn.png";
import Slots3 from "../../../../assets/images/slots/newCasino/SlotsImg2.jpg";
import Slots4 from "../../../../assets/images/slots/newCasino/asgardianstones.jpg";
import Slots5 from "../../../../assets/images/slots/newCasino/secrets-of-atlantis.jpg";
import Slots6 from "../../../../assets/images/slots/newCasino/grand-wheel.jpg";
import Slots7 from "../../../../assets/images/slots/newCasino/777strike.jpg";
import Slots8 from "../../../../assets/images/slots/newCasino/10001-nights.jpg";
import Slots9 from "../../../../assets/images/slots/newCasino/cash_ultimate.jpg";
import Slots10 from "../../../../assets/images/slots/newCasino/cash_volt.jpg";
import Slots11 from "../../../../assets/images/slots/newCasino/dazzle_me_megaways.jpg";
import Slots12 from "../../../../assets/images/slots/newCasino/divine_fortune_megaways.jpg";
import Slots13 from "../../../../assets/images/slots/newCasino/dynamite_riches.jpg";
import Slots14 from "../../../../assets/images/slots/newCasino/fortunehouse.jpg";
import Slots15 from "../../../../assets/images/slots/newCasino/Fruit_Shop_Megaways.jpg";
import Slots16 from "../../../../assets/images/slots/newCasino/Golden_Cryptex.jpg";
import Slots17 from "../../../../assets/images/slots/newCasino/Golden_Tsar.jpg";
import Slots18 from "../../../../assets/images/slots/newCasino/Gorilla_Kingdom.jpg";
import Slots19 from "../../../../assets/images/slots/newCasino/Mystery_Reels_Megaways.jpg";
import Slots20 from "../../../../assets/images/slots/newCasino/Rage_Of_The_Seas.jpg";
import Slots22 from "../../../../assets/images/slots/newCasino/Reel_King_Mega.jpg";
import Slots24 from "../../../../assets/images/slots/newCasino/Turn_Your_Fortune.jpg";
import Slots25 from "../../../../assets/images/slots/newCasino/Twin_Spin_Megaways.jpg";
import Slots27 from "../../../../assets/images/slots/newCasino/Willys_Hot_Chillies.jpg";
import ChineseTreasure from "../../../../assets/images/slots/newCasino/Chinese-Treasures.jpg";
import DragonLuck from "../../../../assets/images/slots/newCasino/Dragon's-Luck.jpg";
import DragonFire from "../../../../assets/images/slots/newCasino/Dragon's-Fire.jpg";
import DragonLuckDeluxe from "../../../../assets/images/slots/newCasino/Dragon's-Luck-Deluxe.jpg";
import DragonLuckReel from "../../../../assets/images/slots/newCasino/Dragon's-Luck-Power-Reels.jpg";
import EpicJourney from "../../../../assets/images/slots/newCasino/Epic-Journey.jpg";
import GodWealth from "../../../../assets/images/slots/newCasino/God-Of-Wealth.jpg";
import LuckyFortune from "../../../../assets/images/slots/newCasino/Lucky-Fortune-Cat.jpg";
import MagicGate from "../../../../assets/images/slots/newCasino/Magic-Gate.jpg";
import GoldenTemple from "../../../../assets/images/slots/newCasino/Golden-Temple.jpg";
import PhoenixPwer from "../../../../assets/images/slots/newCasino/Phoenix-Fire-Power-Reels.jpg";
import GoldenOffer from "../../../../assets/images/slots/newCasino/Golden-Offer.jpg";
import RALegend from "../../../../assets/images/slots/newCasino/RA's-Legend.jpg";
import GonzoMegawaya from "../../../../assets/images/slots/newCasino/Gonzo's-Quest-Megaways.jpg";
import RainbowJAcket from "../../../../assets/images/slots/newCasino/Rainbow-Jackpots.jpg";
import MegaPyramid from "../../../../assets/images/slots/newCasino/Mega-Pyramid.jpg";
import Ozzy_Osbourne from "../../../../assets/images/slots/newCasino/Ozzy_Osbourne.jpg";
import Jimi_Hendrix from "../../../../assets/images/slots/newCasino/Jimi_Hendrix.jpg";
import Jumanji from "../../../../assets/images/slots/newCasino/Jumanji.jpg";
import MotorheadVideoSlot from "../../../../assets/images/slots/newCasino/Motorhead_Video_Slot.jpg";
import Starburst from "../../../../assets/images/slots/newCasino/Starburst.jpg";
import WarofGod from "../../../../assets/images/slots/newCasino/War-Of-Gods.jpg";
import Christmas from "../../../../assets/images/slots/newCasino/Aloha-Christmas.jpg";
import ClusterPays from "../../../../assets/images/slots/newCasino/Aloha-Cluster-Pays.jpg";
import AncientsBlessing from "../../../../assets/images/slots/newCasino/Ancients-Blessing.jpg";
import AurumCodex from "../../../../assets/images/slots/newCasino/Aurum-Codex.jpg";
import GunsNRoses from "../../../../assets/images/slots/newCasino/Guns_N_Roses.jpg";
import BorisandBoo from "../../../../assets/images/slots/newCasino/Betty-Boris-and-Boo.jpg";
import WildWest from "../../../../assets/images/slots/newCasino/Wild-Wild-West-The-Great-Train-Heist.jpg";
import SteamTower from "../../../../assets/images/slots/newCasino/Steam-Tower.jpg";
import ZeusLightning from "../../../../assets/images/slots/newCasino/Zeus-Lightning-Power-Reels.jpg";
import WingsofRa from "../../../../assets/images/slots/newCasino/Wings-of-Ra.jpg";
import Trillionaire from "../../../../assets/images/slots/newCasino/Trillionaire.jpg";
import TreasureMine from "../../../../assets/images/slots/newCasino/Treasure-Mine.jpg";
import ThorVengeance from "../../../../assets/images/slots/newCasino/Thor's-Vengeance.jpg";
import RegalStreak from "../../../../assets/images/slots/newCasino/Regal-Streak.jpg";
import RegalBeasts from "../../../../assets/images/slots/newCasino/Regal-Beasts.jpg";
import ReelKeeper from "../../../../assets/images/slots/newCasino/Reel-Keeper.jpg";
import RainbowJackpotsPowerLines from "../../../../assets/images/slots/newCasino/Rainbow-Jackpots-Power-Lines.jpg";
import PiggyRichesMegaways from "../../../../assets/images/slots/newCasino/Piggy-Riches-Megaways.jpg";
import JingleBells from "../../../../assets/images/slots/newCasino/Jingle-Bells.jpg";
import JackinaPot from "../../../../assets/images/slots/newCasino/Jack-in-a-Pot.jpg";
import FiveStar from "../../../../assets/images/slots/newCasino/Five-Star.jpg";
import BountyRaid from "../../../../assets/images/slots/newCasino/Bounty-Raid.jpg";
import MysteryReels2 from "../../../../assets/images/slots/newCasino/Mystery-Reels.jpg";
import DiamondBlitz from "../../../../assets/images/slots/newCasino/Diamond-Blitz.jpg";
import DragonFireMegaways from "../../../../assets/images/slots/newCasino/Dragon's-Fire-Megaways.jpg";
import Rome from "../../../../assets/images/slots/newCasino/Rome-The-Golden-Age.jpg";
import ReelRush from "../../../../assets/images/slots/newCasino/Reel-Rush.jpg";
import BloodSuckers from "../../../../assets/images/slots/newCasino/Blood-Suckers.jpg";
import BloodSuckers2 from "../../../../assets/images/slots/newCasino/Blood-Suckers-2.jpg";
import Pyramid from "../../../../assets/images/slots/newCasino/Pyramid-Quest-for-Immortality.jpg";
import ButterflyStaxx from "../../../../assets/images/slots/newCasino/Butterfly-Staxx.jpg";
import ButterflyStaxx2 from "../../../../assets/images/slots/newCasino/Butterfly-Staxx-2.jpg";
import PiggyRiches from "../../../../assets/images/slots/newCasino/Piggy-Riches.jpg";
import DarkKing from "../../../../assets/images/slots/newCasino/Dark-King-Forbidden-Riches.jpg";
import JingleBellsPowerReels from "../../../../assets/images/slots/newCasino/Jingle-Bells-Power-Reels.jpg";
import Dazzle from "../../../../assets/images/slots/newCasino/Dazzle-Me.jpg";
import JackHammer from "../../../../assets/images/slots/newCasino/Jack-Hammer.jpg";
import JackHammer2 from "../../../../assets/images/slots/newCasino/Jack-Hammer-2-Fishy-Business.jpg";
import DeadorAlive from "../../../../assets/images/slots/newCasino/Dead-or-Alive.jpg";
import DeadorAlive2 from "../../../../assets/images/slots/newCasino/Dead-or-Alive-2.jpg";
import DeadorAlive2FeatureBuy from "../../../../assets/images/slots/newCasino/Dead-or-Alive-2-Feature-Buy.jpg";
import JackandtheBeanstalk from "../../../../assets/images/slots/newCasino/Jack-and-the-Beanstalk.jpg";
import JingleSpin from "../../../../assets/images/slots/newCasino/Jingle-Spin.jpg";
import GordonRamsayHellsKitchen from "../../../../assets/images/slots/newCasino/Gordon_Ramsay_Hells_Kitchen.jpg";
import Elements from "../../../../assets/images/slots/newCasino/Elements-The-Awakening.jpg";
import GonzosQuest from "../../../../assets/images/slots/newCasino/Gonzos_Quest.jpg";
import FinnandtheSwirlySpin from "../../../../assets/images/slots/newCasino/Finn-and-the-Swirly-Spin.jpg";
import FruitShop from "../../../../assets/images/slots/newCasino/Fruit-Shop.jpg";
import DazzleMeMegaways from "../../../../assets/images/slots/newCasino/Dazzle-Me.jpg";
import DoubleStacks from "../../../../assets/images/slots/newCasino/Double-Stacks.jpg";
import FairytaleLegends from "../../../../assets/images/slots/newCasino/Fairytale-Legends-Red-Riding-Hood.jpg";
import Flowers from "../../../../assets/images/slots/newCasino/Flowers.jpg";
import FruitShopChristmasEdition from "../../../../assets/images/slots/newCasino/Fruit-Shop-Christmas-Edition.jpg";
import Hotline from "../../../../assets/images/slots/newCasino/Hotline.jpg";
import Hotline2 from "../../../../assets/images/slots/newCasino/Hotline-2.jpg";
import TheInvisibleMan from "../../../../assets/images/slots/newCasino/The_Invisible_Man.jpg";
import IrishPotLuck from "../../../../assets/images/slots/newCasino/Irish-Pot-Luck.jpg";
import FruitSpin from "../../../../assets/images/slots/newCasino/Fruit-Spin.jpg";
import JungleSpirit from "../../../../assets/images/slots/newCasino/Jungle-Spirit-Call-of-the-Wild.jpg";
import KoiPrincess from "../../../../assets/images/slots/newCasino/Koi-Princess.jpg";
import DriveMultiplierMayhem from "../../../../assets/images/slots/newCasino/Drive-Multiplier-Mayhem.jpg";
import RageoftheSeas from "../../../../assets/images/slots/newCasino/Rage-of-the-Seas.jpg";
import RichesofMidgard from "../../../../assets/images/slots/newCasino/Riches-of-Midgard-Land-and-Expand.jpg";
import ScruffyDuck from "../../../../assets/images/slots/newCasino/Scruffy-Duck.jpg";
import SecretoftheStones from "../../../../assets/images/slots/newCasino/Secret-of-the-Stones.jpg";
import SecretsofChristmas from "../../../../assets/images/slots/newCasino/Secrets-of-Christmas.jpg";
import SerengetiKings from "../../../../assets/images/slots/newCasino/Serengeti-Kings.jpg";
import SpaceWars from "../../../../assets/images/slots/newCasino/Space-Wars.jpg";
import Victorious from "../../../../assets/images/slots/newCasino/Victorious.jpg";
import Warlords from "../../../../assets/images/slots/newCasino/Warlords-Crystal-of-Power.jpg";
import WildWater from "../../../../assets/images/slots/newCasino/Wild-Water.jpg";
import WildWorlds from "../../../../assets/images/slots/newCasino/Wild-Worlds.jpg";
import Squad4 from "../../../../assets/images/slots/newCasino/4-Squad.jpg";
import Families5 from "../../../../assets/images/slots/newCasino/5-Families.jpg";
import AgentRoyale from "../../../../assets/images/slots/newCasino/Agent-Royale.jpg";
import ArcadeBomb from "../../../../assets/images/slots/newCasino/Arcade-Bomb.jpg";
import Atlantis from "../../../../assets/images/slots/newCasino/Atlantis.jpg";
import AztecSpins from "../../../../assets/images/slots/newCasino/Aztec-Spins.jpg";
import CinderellaBall from "../../../../assets/images/slots/newCasino/Cinderella`s-Ball.jpg";
import CirquedelaFortune from "../../../../assets/images/slots/newCasino/Cirque-de-la-Fortune.jpg";
import ClashoftheBeasts from "../../../../assets/images/slots/newCasino/Clash-of-the-Beasts.jpg";
import CrazyGenie from "../../../../assets/images/slots/newCasino/Crazy-Genie.jpg";
import CrystalMirror from "../../../../assets/images/slots/newCasino/Crystal-Mirror.jpg";
import DaVinciMystery from "../../../../assets/images/slots/newCasino/Da-Vinci`s-Mystery.jpg";
import DevilNumber from "../../../../assets/images/slots/newCasino/Devil`s-Number.jpg";
import DiceDice from "../../../../assets/images/slots/newCasino/Dice-Dice-Dice.jpg";
import FiveStarsPowerReels from "../../../../assets/images/slots/newCasino/Five-Stars-Power-Reels.jpg";
import GemsGoneWildPowerReels from "../../../../assets/images/slots/newCasino/Gems-Gone-Wild-Power-Reels.jpg";
import GemsGoneWild from "../../../../assets/images/slots/newCasino/Gems-Gone-Wild.jpg";
import GoldenLeprechaunMegaways from "../../../../assets/images/slots/newCasino/Golden-Leprechaun-Megaways.jpg";
import HoardofPoseidon from "../../../../assets/images/slots/newCasino/Hoard-of-Poseidon.jpg";
import JewelScarabs from "../../../../assets/images/slots/newCasino/Jewel-Scarabs.jpg";
import LegendofAthena from "../../../../assets/images/slots/newCasino/Legend-of-Athena.jpg";
import LuckyHalloween from "../../../../assets/images/slots/newCasino/Lucky-Halloween.jpg";
import LuckyLittleDevil from "../../../../assets/images/slots/newCasino/Lucky-Little-Devil.jpg";
import LuckyValentine from "../../../../assets/images/slots/newCasino/Lucky-Valentine.jpg";
import LuckyWizard from "../../../../assets/images/slots/newCasino/Lucky-Wizard.jpg";
import Masquerade from "../../../../assets/images/slots/newCasino/Masquerade.jpg";
import MayanGods from "../../../../assets/images/slots/newCasino/Mayan-Gods.jpg";
import MegaDragon from "../../../../assets/images/slots/newCasino/Mega-Dragon.jpg";
import MegaJade from "../../../../assets/images/slots/newCasino/Mega-Jade.jpg";
import MegaRise from "../../../../assets/images/slots/newCasino/Mega-Rise.jpg";
import MultiplierRiches from "../../../../assets/images/slots/newCasino/Multiplier-Riches.jpg";
import MysteryReelsMegaways from "../../../../assets/images/slots/newCasino/Mystery_Reels_Megaways.jpg";
import NinjaWays from "../../../../assets/images/slots/newCasino/Ninja-Ways.jpg";
import PathofDestiny from "../../../../assets/images/slots/newCasino/Path-of-Destiny.jpg";
import PersianFortune from "../../../../assets/images/slots/newCasino/Persian-Fortune.jpg";
import ReelHeist from "../../../../assets/images/slots/newCasino/Reel-Heist.jpg";
import RobinHoodWildForest from "../../../../assets/images/slots/newCasino/Robin-Hood`s-Wild-Forest.jpg";
import SnowWild from "../../../../assets/images/slots/newCasino/Snow-Wild-And-The-7-Features.jpg";
import SylvanSpirits from "../../../../assets/images/slots/newCasino/Sylvan-Spirits.jpg";
import TheGreatestTrainRobbery from "../../../../assets/images/slots/newCasino/The-Greatest-Train-Robbery.jpg";
import TheWildHatter from "../../../../assets/images/slots/newCasino/The-Wild-Hatter.jpg";
import ThorLightning from "../../../../assets/images/slots/newCasino/Thor`s-Lightning.jpg";
import TikiFruits from "../../../../assets/images/slots/newCasino/Tiki-Fruits.jpg";
import TotemLightning from "../../../../assets/images/slots/newCasino/Totem-Lightning.jpg";
import VaultCracker from "../../../../assets/images/slots/newCasino/Vault-Cracker.jpg";
import VaultofAnubis from "../../../../assets/images/slots/newCasino/Vault-of-Anubis.jpg";
import WellofWishes from "../../../../assets/images/slots/newCasino/Well-of-Wishes.jpg";
import WildCatsMultiline from "../../../../assets/images/slots/newCasino/Wild-Cats-Multiline.jpg";
import WildOClock from "../../../../assets/images/slots/newCasino/Wild-O`Clock.jpg";
import WildChest from "../../../../assets/images/slots/newCasino/Wild-Wild-Chest.jpg";
import WinEscalator from "../../../../assets/images/slots/newCasino/Win-Escalator.jpg";
import YucatanMystery from "../../../../assets/images/slots/newCasino/Yucatan`s-Mystery.jpg";

import { useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import BackIcon from "../../../../assets/svg/BackIcon";
import { useNavigate } from "react-router-dom";

const Slots = () => {
  const navigate = useNavigate();
  const { isAuth } = useSelector((state) => state.auth);
  return (
    <div>
      <HeaderAfterLogin />

      <main className="main">
        <Container>
          <div className="slots-section">
            <div className="pageHeadingSec">
              <h2>Slots</h2>
              <a href="#" className="back-link" onClick={() => navigate(-1)}>
                Back <BackIcon />
              </a>
            </div>
            <div className="games-section">
              <ul>
                {/* <li>
                  <a href="/newCasino/spribe/aviator">
                    <img src={Slots1} alt="Live newCasino game" />
                    <div className="newCasino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li> */}
                {/* <li>
                  <a href="/newCasino/ezugi/twinspin">
                    <img src={Slots2} alt="Live newCasino game" />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>*/}
                <li>
                  <a href="/casino/ezugi/gonzoquest">
                    <img src={Slots3} alt="Live casino game" />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/asgardianstones">
                    <img src={Slots4} alt="Live casino game" />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/secrets">
                    <img src={Slots5} alt="Live casino game" />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/grandwheel">
                    <img src={Slots6} alt="Live casino game" />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/Strike777">
                    <img src={Slots7} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/Nights1000">
                    <img src={Slots8} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/CashUltimate">
                    <img src={Slots9} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/CashVolt">
                    <img src={Slots10} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/DazzleMegaways">
                    <img src={Slots11} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/DivinieFortuneMegaways">
                    <img src={Slots12} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/Dynamiteriches">
                    <img src={Slots13} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/FortuneHouse">
                    <img src={Slots14} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/FruitShopMegaways">
                    <img src={Slots15} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/GoldenCryptex">
                    <img src={Slots16} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/GoldenTsar">
                    <img src={Slots17} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>

                <li>
                  <a href="/casino/ezugi/GorillaKingdom">
                    <img src={Slots18} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/MystryReels">
                    <img src={Slots19} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/RageoftheSea">
                    <img src={Slots20} alt="Live casino game" />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>

                <li>
                  <a href="/casino/ezugi/ReelKingMegaways">
                    <img src={Slots22} alt="Live casino game" />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>

                <li>
                  <a href="/casino/ezugi/TurnFortune">
                    <img src={Slots24} alt="Live casino game" />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/TwinSpinMegaways">
                    <img src={Slots25} alt="Live casino game" />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>

                <li>
                  <a href="/casino/ezugi/WillysChills">
                    <img src={Slots27} alt="Live casino game" />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a
                    href={isAuth ? "/casino/ezugi/ChineseTreasures" : "/login"}
                  >
                    <img src={ChineseTreasure} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/DragonLuck" : "/login"}>
                    <img src={DragonLuck} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? " /casino/ezugi/DragonFire" : "/login"}>
                    <img src={DragonFire} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a
                    href={isAuth ? " /casino/ezugi/DragonLuckDeluxe" : "/login"}
                  >
                    <img src={DragonLuckDeluxe} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? " /casino/ezugi/DragonLuckReel" : "/login"}>
                    <img src={DragonLuckReel} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? " /casino/ezugi/EpicJourney" : "/login"}>
                    <img src={EpicJourney} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? " /casino/ezugi/GodWealth" : "/login"}>
                    <img src={GodWealth} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? " /casino/ezugi/LuckyFortune" : "/login"}>
                    <img src={LuckyFortune} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? " /casino/ezugi/MagicGate" : "/login"}>
                    <img src={MagicGate} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/GoldenTemple" : "/login"}>
                    <img src={GoldenTemple} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/PhoenixPower" : "/login"}>
                    <img src={PhoenixPwer} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>

                <li>
                  <a href={isAuth ? "/casino/ezugi/GoldenOffer" : "/login"}>
                    <img src={GoldenOffer} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/RAsLegend" : "/login"}>
                    <img src={RALegend} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/GonzoMegawaya" : "/login"}>
                    <img src={GonzoMegawaya} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? " /casino/ezugi/RainbowJAcket" : "/login"}>
                    <img src={RainbowJAcket} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? " /casino/ezugi/MegaPyramid" : "/login"}>
                    <img src={MegaPyramid} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>

                {/* <li>
                  <a href={isAuth ? "/1100020/ezugi/Ozzy_Osbourne" : "/login"}>
                    <img src={Ozzy_Osbourne} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li> */}
                <li>
                  <a href={isAuth ? "/casino/ezugi/Jimi_Hendrix" : "/login"}>
                    <img src={Jimi_Hendrix} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/Jumanji" : "/login"}>
                    <img src={Jumanji} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a
                    href={
                      isAuth ? "/casino/ezugi/MotorheadVideoSlot" : "/login"
                    }
                  >
                    <img src={MotorheadVideoSlot} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/Starburst" : "/login"}>
                    <img src={Starburst} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/WarofGod" : "/login"}>
                    <img src={WarofGod} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/Christmas" : "/login"}>
                    <img src={Christmas} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/ClusterPays" : "/login"}>
                    <img src={ClusterPays} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a
                    href={isAuth ? "/casino/ezugi/AncientsBlessing" : "/login"}
                  >
                    <img src={AncientsBlessing} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/AurumCodex" : "/login"}>
                    <img src={AurumCodex} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/GunsNRoses" : "/login"}>
                    <img src={GunsNRoses} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/BorisandBoo" : "/login"}>
                    <img src={BorisandBoo} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/WildWest" : "/login"}>
                    <img src={WildWest} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/SteamTower" : "/login"}>
                    <img src={SteamTower} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/ZeusLightning" : "/login"}>
                    <img src={ZeusLightning} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/WingsofRa" : "/login"}>
                    <img src={WingsofRa} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/Trillionaire" : "/login"}>
                    <img src={Trillionaire} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/TreasureMine" : "/login"}>
                    <img src={TreasureMine} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/ThorVengeance" : "/login"}>
                    <img src={ThorVengeance} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/RegalStreak" : "/login"}>
                    <img src={RegalStreak} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/RegalBeasts" : "/login"}>
                    <img src={RegalBeasts} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/ReelKeeper" : "/login"}>
                    <img src={ReelKeeper} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a
                    href={
                      isAuth
                        ? "/casino/ezugi/RainbowJackpotsPowerLines"
                        : "/login"
                    }
                  >
                    <img src={RainbowJackpotsPowerLines} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a
                    href={
                      isAuth ? "/casino/ezugi/PiggyRichesMegaways" : "/login"
                    }
                  >
                    <img src={PiggyRichesMegaways} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/JingleBells" : "/login"}>
                    <img src={JingleBells} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/JackinaPot" : "/login"}>
                    <img src={JackinaPot} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/FiveStar" : "/login"}>
                    <img src={FiveStar} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/BountyRaid" : "/login"}>
                    <img src={BountyRaid} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/MysteryReels2" : "/login"}>
                    <img src={MysteryReels2} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/DiamondBlitz" : "/login"}>
                    <img src={DiamondBlitz} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a
                    href={
                      isAuth ? "/casino/ezugi/DragonFireMegaways" : "/login"
                    }
                  >
                    <img src={DragonFireMegaways} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/Rome" : "/login"}>
                    <img src={Rome} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/ReelRush" : "/login"}>
                    <img src={ReelRush} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/BloodSuckers" : "/login"}>
                    <img src={BloodSuckers} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/Pyramid" : "/login"}>
                    <img src={Pyramid} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/ButterflyStaxx" : "/login"}>
                    <img src={ButterflyStaxx} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/PiggyRiches" : "/login"}>
                    <img src={PiggyRiches} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/DarkKing" : "/login"}>
                    <img src={DarkKing} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/JingleSpin" : "/login"}>
                    <img src={JingleSpin} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/Dazzle" : "/login"}>
                    <img src={Dazzle} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/JackHammer" : "/login"}>
                    <img src={JackHammer} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/DeadorAlive" : "/login"}>
                    <img src={DeadorAlive} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a
                    href={
                      isAuth ? "/casino/ezugi/JackandtheBeanstalk" : "/login"
                    }
                  >
                    <img src={JackandtheBeanstalk} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/DeadorAlive2" : "/login"}>
                    <img src={DeadorAlive2} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>

                <li>
                  <a
                    href={
                      isAuth
                        ? "/casino/ezugi/GordonRamsayHellsKitchen"
                        : "/login"
                    }
                  >
                    <img src={GordonRamsayHellsKitchen} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/Elements" : "/login"}>
                    <img src={Elements} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/GonzosQuest" : "/login"}>
                    <img src={GonzosQuest} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a
                    href={
                      isAuth ? "/casino/ezugi/FinnandtheSwirlySpin" : "/login"
                    }
                  >
                    <img src={FinnandtheSwirlySpin} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>

                <li>
                  <a href={isAuth ? "/casino/ezugi/FruitShop" : "/login"}>
                    <img src={FruitShop} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/BloodSuckers2" : "/login"}>
                    <img src={BloodSuckers2} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/ButterflyStaxx2" : "/login"}>
                    <img src={ButterflyStaxx2} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a
                    href={isAuth ? "/casino/ezugi/DazzleMeMegaways" : "/login"}
                  >
                    <img src={DazzleMeMegaways} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a
                    href={
                      isAuth ? "/casino/ezugi/DeadorAlive2FeatureBuy" : "/login"
                    }
                  >
                    <img src={DeadorAlive2FeatureBuy} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/DoubleStacks" : "/login"}>
                    <img src={DoubleStacks} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a
                    href={isAuth ? "/casino/ezugi/FairytaleLegends" : "/login"}
                  >
                    <img src={FairytaleLegends} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/Flowers" : "/login"}>
                    <img src={Flowers} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a
                    href={
                      isAuth
                        ? "/casino/ezugi/FruitShopChristmasEdition"
                        : "/login"
                    }
                  >
                    <img src={FruitShopChristmasEdition} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/Hotline" : "/login"}>
                    <img src={Hotline} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/Hotline2" : "/login"}>
                    <img src={Hotline2} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/TheInvisibleMan" : "/login"}>
                    <img src={TheInvisibleMan} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/IrishPotLuck" : "/login"}>
                    <img src={IrishPotLuck} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/JackHammer2" : "/login"}>
                    <img src={JackHammer2} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/FruitSpin" : "/login"}>
                    <img src={FruitSpin} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/JungleSpirit" : "/login"}>
                    <img src={JungleSpirit} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/KoiPrincess" : "/login"}>
                    <img src={KoiPrincess} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a
                    href={
                      isAuth ? "/casino/ezugi/DriveMultiplierMayhem" : "/login"
                    }
                  >
                    <img src={DriveMultiplierMayhem} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/RageoftheSeas" : "/login"}>
                    <img src={RageoftheSeas} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/RichesofMidgard" : "/login"}>
                    <img src={RichesofMidgard} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/ScruffyDuck" : "/login"}>
                    <img src={ScruffyDuck} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a
                    href={isAuth ? "/casino/ezugi/SecretoftheStones" : "/login"}
                  >
                    <img src={SecretoftheStones} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a
                    href={
                      isAuth ? "/casino/ezugi/SecretsofChristmas" : "/login"
                    }
                  >
                    <img src={SecretsofChristmas} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/SerengetiKings" : "/login"}>
                    <img src={SerengetiKings} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/SpaceWars" : "/login"}>
                    <img src={SpaceWars} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/Victorious" : "/login"}>
                    <img src={Victorious} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/Warlords" : "/login"}>
                    <img src={Warlords} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/WildWater" : "/login"}>
                    <img src={WildWater} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/WildWorlds" : "/login"}>
                    <img src={WildWorlds} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/Squad4" : "/login"}>
                    <img src={Squad4} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/Families5" : "/login"}>
                    <img src={Families5} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/AgentRoyale" : "/login"}>
                    <img src={AgentRoyale} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/ArcadeBomb" : "/login"}>
                    <img src={ArcadeBomb} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/Atlantis" : "/login"}>
                    <img src={Atlantis} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/AztecSpins" : "/login"}>
                    <img src={AztecSpins} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/CinderellaBall" : "/login"}>
                    <img src={CinderellaBall} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a
                    href={isAuth ? "/casino/ezugi/CirquedelaFortune" : "/login"}
                  >
                    <img src={CirquedelaFortune} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a
                    href={isAuth ? "/casino/ezugi/ClashoftheBeasts" : "/login"}
                  >
                    <img src={ClashoftheBeasts} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/CrazyGenie" : "/login"}>
                    <img src={CrazyGenie} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/CrystalMirror" : "/login"}>
                    <img src={CrystalMirror} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/DaVinciMystery" : "/login"}>
                    <img src={DaVinciMystery} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/DevilNumber" : "/login"}>
                    <img src={DevilNumber} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/DiceDice" : "/login"}>
                    <img src={DiceDice} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a
                    href={
                      isAuth ? "/casino/ezugi/FiveStarsPowerReels" : "/login"
                    }
                  >
                    <img src={FiveStarsPowerReels} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/GemsGoneWild" : "/login"}>
                    <img src={GemsGoneWild} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a
                    href={
                      isAuth ? "/casino/ezugi/GemsGoneWildPowerReels" : "/login"
                    }
                  >
                    <img src={GemsGoneWildPowerReels} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a
                    href={
                      isAuth
                        ? "/casino/ezugi/GoldenLeprechaunMegaways"
                        : "/login"
                    }
                  >
                    <img src={GoldenLeprechaunMegaways} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/HoardofPoseidon" : "/login"}>
                    <img src={HoardofPoseidon} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/JewelScarabs" : "/login"}>
                    <img src={JewelScarabs} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a
                    href={
                      isAuth ? "/casino/ezugi/JingleBellsPowerReels" : "/login"
                    }
                  >
                    <img src={JingleBellsPowerReels} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/LegendofAthena" : "/login"}>
                    <img src={LegendofAthena} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/LuckyHalloween" : "/login"}>
                    <img src={LuckyHalloween} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a
                    href={isAuth ? "/casino/ezugi/LuckyLittleDevil" : "/login"}
                  >
                    <img src={LuckyLittleDevil} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/LuckyValentine" : "/login"}>
                    <img src={LuckyValentine} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/LuckyWizard" : "/login"}>
                    <img src={LuckyWizard} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/Masquerade" : "/login"}>
                    <img src={Masquerade} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/MayanGods" : "/login"}>
                    <img src={MayanGods} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/MegaDragon" : "/login"}>
                    <img src={MegaDragon} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/MegaJade" : "/login"}>
                    <img src={MegaJade} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/MegaRise" : "/login"}>
                    <img src={MegaRise} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a
                    href={isAuth ? "/casino/ezugi/MultiplierRiches" : "/login"}
                  >
                    <img src={MultiplierRiches} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a
                    href={
                      isAuth ? "/casino/ezugi/MysteryReelsMegaways" : "/login"
                    }
                  >
                    <img src={MysteryReelsMegaways} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/NinjaWays" : "/login"}>
                    <img src={NinjaWays} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/PathofDestiny" : "/login"}>
                    <img src={PathofDestiny} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/PersianFortune" : "/login"}>
                    <img src={PersianFortune} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/ReelHeist" : "/login"}>
                    <img src={ReelHeist} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a
                    href={
                      isAuth ? "/casino/ezugi/RobinHoodWildForest" : "/login"
                    }
                  >
                    <img src={RobinHoodWildForest} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/SnowWild" : "/login"}>
                    <img src={SnowWild} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/SylvanSpirits" : "/login"}>
                    <img src={SylvanSpirits} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a
                    href={
                      isAuth
                        ? "/casino/ezugi/TheGreatestTrainRobbery"
                        : "/login"
                    }
                  >
                    <img src={TheGreatestTrainRobbery} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/TheWildHatter" : "/login"}>
                    <img src={TheWildHatter} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/ThorLightning" : "/login"}>
                    <img src={ThorLightning} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/TikiFruits" : "/login"}>
                    <img src={TikiFruits} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/TotemLightning" : "/login"}>
                    <img src={TotemLightning} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/VaultCracker" : "/login"}>
                    <img src={VaultCracker} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/VaultofAnubis" : "/login"}>
                    <img src={VaultofAnubis} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/WellofWishes" : "/login"}>
                    <img src={WellofWishes} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a
                    href={isAuth ? "/casino/ezugi/WildCatsMultiline" : "/login"}
                  >
                    <img src={WildCatsMultiline} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/WildOClock" : "/login"}>
                    <img src={WildOClock} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/WildChest" : "/login"}>
                    <img src={WildChest} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/WinEscalator" : "/login"}>
                    <img src={WinEscalator} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href={isAuth ? "/casino/ezugi/YucatanMystery" : "/login"}>
                    <img src={YucatanMystery} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </Container>
      </main>

      <Footer />
    </div>
  );
};

export default Slots;
