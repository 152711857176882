import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { APP_CONST } from "../config/const";
import { balanceUpdate, logoutUser } from "../redux/auth/actions";
import { socketFunc } from "../utils/socketUtil";
import { Toaster } from "../utils/toaster";

function SocketProvider({ children }) {
  const token = useSelector((state) => state.auth?.token);
  const dispatch = useDispatch();

  useEffect(() => {
    const socket = socketFunc(token);

    if (token) {
      socket.emit("session_check", (data) => {
        if (data?.status === "failed") {
          dispatch(logoutUser());
          window.location.href = "/";
        }
      });

      socket.on("session_refresh", () => {
        socket.emit("session_check", (data) => {
          if (data?.status === "failed") {
            dispatch(logoutUser());
            window.location.href = "/";
          }
        });
      });

      socket.on("connect_error", async (error) => {
        // console.log("errorname", error.message, error.data);
        // console.log(error, error.name);
        if (error?.data?.type === "SessionError") {
          Toaster(APP_CONST.TOAST_TYPE.ERROR, error?.data?.message);
          dispatch(logoutUser());
          window.location.href = "/";
          return;
        }
        // revert to classic upgrade
        socket.io.opts.transports = ["polling", "websocket"];
      });

      socket.emit("get:balance", (data) => {
        if (data?.status === "success") {
          dispatch(balanceUpdate(data));
        }
      });

      socket.on("update_balance", (data) => {
        if (!isNaN(parseFloat(data.balance))) {
          dispatch(balanceUpdate(data));
        } else {
          socket.emit("get:balance", (data) => {
            if (data?.status === "success") {
              dispatch(balanceUpdate(data));
            }
          });
        }
      });
    }
    // return () => {};

    return () => {
      socket.off("session_refresh");
      socket.off("update_balance");
    };
  }, [token, dispatch]);

  return <>{children}</>;
}

export default SocketProvider;
