import React, { useEffect, useLayoutEffect, useState } from "react";
import { APP_CONST } from "../config/const";
import LoginModal from "../views/components/authModals/LoginModal";
import MobileNumberModal from "../views/components/authModals/MobileNumberModal";
import MobileNumberVerifyModal from "../views/components/authModals/MobileNumberVerifyModal";
import RegisterModal from "../views/components/authModals/RegisterModal";
import ForgotPasswords from "../views/components/authModals/ForgotPassword";
import ResetPassword from "../views/components/authModals/ResetPassword";

const ModalWrapper = ({
  defaultShow,
  setShowAuthModals: updateComponentState,
  agentCode = "",
}) => {
  const [showAuthModals, setShowAuthModals] = useState({
    login: false,
    register: false,
    mobileNumber: false,
    verifyNumber: false,
    forgotpassword: false,
    resetpassword: false,
  });
  useEffect(() => {
    if (defaultShow && defaultShow in showAuthModals) {
      handleModalShow(defaultShow);
    }
  }, [defaultShow]);

  useEffect(() => {}, [showAuthModals]);
  const handleModalShow = (modalName) => {
    let tempObj = { ...showAuthModals };
    Object.keys(tempObj).forEach((key, index) => {
      tempObj[key] = false;
    });
    tempObj[modalName] = true;
    setShowAuthModals({ ...tempObj });
  };

  const handleModalClose = (modalName, type = "manual") => {
    setShowAuthModals((p) => ({ ...p, [modalName]: false }));
    if (updateComponentState && type == "manual") {
      updateComponentState(false);
    }
  };
  return (
    <>
      {showAuthModals.login && (
        <LoginModal
          show={showAuthModals.login}
          handleClose={handleModalClose}
          handleModalShow={handleModalShow}
        />
      )}
      {showAuthModals.mobileNumber && (
        <MobileNumberModal
          show={showAuthModals.mobileNumber}
          handleClose={handleModalClose}
          handleModalShow={handleModalShow}
        />
      )}
      {showAuthModals.verifyNumber && (
        <MobileNumberVerifyModal
          show={showAuthModals.verifyNumber}
          handleClose={handleModalClose}
          handleModalShow={handleModalShow}
        />
      )}
      {showAuthModals.register && (
        <RegisterModal
          show={showAuthModals.register}
          handleClose={handleModalClose}
          handleModalShow={handleModalShow}
          agentCode={agentCode}
        />
      )}

      {showAuthModals.forgotpassword && (
        <ForgotPasswords
          show={showAuthModals.forgotpassword}
          handleClose={handleModalClose}
          handleModalShow={handleModalShow}
        />
      )}
      {showAuthModals.resetpassword && (
        <ResetPassword
          show={showAuthModals.resetpassword}
          handleClose={() =>
            handleModalClose(APP_CONST.AUTH_MODAL.RESET_PASSWORD, "auto")
          }
          handleModalShow={handleModalShow}
        />
      )}
    </>
  );
};
export default ModalWrapper;
