import React, { useState } from "react";
import { forgotPassword } from "../../../redux/auth/actions";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
// import EyeIcon from "../../../../assets/svg/EyeIcon";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import parsePhoneNumber from "libphonenumber-js";
import { Toaster } from "../../../utils/toaster";
import { APP_CONST } from "../../../config/const";
import { Modal } from "react-bootstrap";
import Whatapps from "../../../assets/images/icons/social/whatsapp.png";
import Telegram from "../../../assets/images/icons/social/telegram.png";
import FACEBOOK from "../../../assets/images/icons/social/facebook.png";
import INSTATGRAM from "../../../assets/images/icons/social/telegram.png";
import Twitter from "../../../assets/images/icons/social/twitter.png";
import Youtube from "../../../assets/images/icons/social/youtube.png";

import LoginImg from "../../../assets/images/banner/login.jpg";
import { useSelector } from "react-redux";

const ForgotPasswords = ({ show, handleClose, handleModalShow }) => {
  const { appDetails } = useSelector((state) => state.app);
  const navigate = useNavigate();
  const [mobileNumber, setMobileNumber] = useState("");
  const handleSubmit = async () => {
    const phoneNumber = parsePhoneNumber("+" + mobileNumber);
    let payload = {};
    console.log("here");
    if (phoneNumber) {
      payload.phone = phoneNumber.nationalNumber;
      payload.countryCode = phoneNumber.countryCallingCode;
      forgotPassword(payload)
        .then((res) => {
          if (res.data.error === false) {
            Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "OTP sent");
            // navigate("/reset-password");
            handleClose(APP_CONST.AUTH_MODAL.FORGOTO_PASSWORD, "auto");
            handleModalShow(APP_CONST.AUTH_MODAL.RESET_PASSWORD);
          }
        })
        .catch((err) => {
          Toaster(APP_CONST.TOAST_TYPE.ERROR, err.response.data.message);
        });
    } else {
      Toaster(APP_CONST.TOAST_TYPE.ERROR, "Please enter mobile number");
    }
  };
  return (
    <Modal
      show={show}
      className={"steps-canvas login-modal"}
      onHide={() => {
        console.log("working");
      }}
      placement={"end"}
    >
      <Modal.Header
        closeButton
        onClick={() =>
          handleClose(APP_CONST.AUTH_MODAL.FORGOTO_PASSWORD, "manual")
        }
        closeVariant="black"
      >
        <Modal.Title style={{ color: "#013862" }}>Forgot Password</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ background: "#000a2c" }}>
        <div className="login-section">
          {/* <div className="Modal-left-part">
              {" "}
              <img src={LoginImg} alt="login img" />
            </div> */}
          <div className="Modal-right-part">
            {/* <img src={appDetails?.LOGO_URL} alt="Logo" /> */}
            <div className="Login-rightPart">
              {/* <Modal.Title>Sign in now!</Modal.Title> */}
              <div className="form-steps">
                <h2 className="h2">Hello Again!</h2>
                <p>
                  Welcome back, you've
                  <br />
                  been missed!
                </p>
                <Form>
                  <Form.Group
                    className="mb-3 position-relative"
                    controlId="formBasicPassword"
                  >
                    <PhoneInput
                      country={"in"}
                      inputProps={{
                        name: "phone",
                        required: true,
                      }}
                      // localization={es}
                      onlyCountries={["in"]}
                      inputStyle={{
                        display: "block",
                        width: "100%",
                        lineHeight: 1.5,
                        color: "#fff",
                        backgroundColor: "#26324B",
                        backgroundClip: "padding-box",
                        borderRadius: "10px",
                        padding: "0.7rem 0.75rem",
                        borderColor: "#26324B",
                        fontSize: "0.86rem",
                        minHeight: "41px",
                        overflow: "hidden",
                        paddingLeft: "50px",
                      }}
                      onChange={(phone) => {
                        // alert("phone", phone);
                        // alert(phone);
                        setMobileNumber(phone);
                      }}
                    />
                  </Form.Group>
                </Form>

                <Button variant="primary" type="submit" onClick={handleSubmit}>
                  Submit
                </Button>

                <div className="SocialIconSection d-none">
                  <p>Need help? Live Chat Support - 24/7</p>
                  <ul>
                    {" "}
                    {/* <li>
                        <a
                          href={
                            appDetails?.WHATS_APP_URL != ""
                              ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                              : "#"
                          }
                          target={
                            appDetails?.WHATS_APP_URL != "" ? "_blank" : ""
                          }
                        >
                          <img src={Whatapps} alt="Calling icon" />
                        </a>
                      </li> */}
                    <li>
                      <a
                        href={
                          appDetails?.TELEGRAM != ""
                            ? appDetails?.TELEGRAM
                            : "#"
                        }
                        target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                      >
                        <img src={Telegram} alt="Calling icon" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={
                          appDetails?.FACEBOOK != ""
                            ? appDetails?.FACEBOOK
                            : "#"
                        }
                        target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
                      >
                        <img src={FACEBOOK} alt="Calling icon" />
                      </a>
                    </li>
                    {/* <li>
                        <a
                          href={
                            appDetails?.INSTATGRAM != ""
                              ? appDetails?.INSTATGRAM
                              : "#"
                          }
                          target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
                        >
                          <img src={INSTATGRAM} alt="Calling icon" />
                        </a>
                      </li> */}
                    <li>
                      <a href="#">
                        <img src={Twitter} alt="Calling icon" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src={Youtube} alt="Calling icon" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default ForgotPasswords;
