import React, { useEffect, useRef, useState } from "react";
import { Offcanvas, Nav, Navbar, Button } from "react-bootstrap";
import MenuIcon from "../assets/images/menu-icon.png";
import TelegramIcon from "../assets/images/telegramIcon.svg";
import WhatsappIcon from "../assets/images/whatsappIcon.svg";
import NotificationIcon from "../assets/images/notificationIcon.svg";
import UserIcon from "../assets/images/user-icon.svg";
import BalanceIcon from "../assets/images/balance-icon.png";
import CashierIconNew from "../assets/images/Plus.png";
import WalletIcon from "../assets/images/WalletIcon.png";
import BetIcon from "../assets/images/BetIcon.png";
import AcStatement from "../assets/images/AcStatement.png";
import AcSettings from "../assets/images/AcSettings.svg";
import MoonLight from "../assets/images/moon.svg";
import SunLight from "../assets/images/sunny.svg";

import { FaRegMoneyBillAlt } from "react-icons/fa";
import { MdOutlineVerified } from "react-icons/md";
import { BiSupport } from "react-icons/bi";
import { AiOutlineFileDone } from "react-icons/ai";
import LogoutIconImg from "../assets/images/logout.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../redux/auth/actions";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.png";
import BonusImg from "../assets/images/bonus.png";
import refferal from "../assets/images/refferal.png";
import MobLeftbar from "./MobLeftbar";
import { AiOutlineSearch } from "react-icons/ai";
import GamesSearchModal from "../views/components/GamesSearchModal";
import { useOutsideAlerter } from "../hooks/useOutsideClick";
import { replaceUniqueString } from "../utils/manageUniqueUser";
import { getNotifications } from "../redux/app/actions";
import { AiOutlineClose } from "react-icons/ai";

const headerPages = [
  "home",
  "sportsbook",
  "live-dealer",
  "evolution",
  "supernowa",
  "aviator",
];

const HeaderAfterLogin = () => {
  const { user, balance, wallet } = useSelector((state) => state.auth);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const dispatch = useDispatch();
  const { appDetails } = useSelector((state) => state.app);
  const [isClassAdded, setIsClassAdded] = useState(false);
  const [showMobileSideBar, setShowMobileSideBar] = useState(false);
  const [websiteNotifications, setWebsiteNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(true);
  const location = useLocation();
  const [activePage, setActivePage] = useState("");
  const containerRef = useRef(null);
  useOutsideAlerter(containerRef, setShowMobileSideBar);

  useEffect(() => {
    const isClassAddedLocalStorage = localStorage.getItem("isClassAdded");
    if (isClassAddedLocalStorage) {
      setIsClassAdded(true);
      document.body.classList.add("light-theme");
    }
    loadNotifications();
  }, []);

  useEffect(() => {
    const matchedValue = headerPages.find((f) =>
      location?.pathname.includes(f)
    );
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  const toggleClass = () => {
    if (isClassAdded) {
      setIsClassAdded(false);
      localStorage.removeItem("isClassAdded");
      document.body.classList.remove("light-theme");
    } else {
      setIsClassAdded(true);
      localStorage.setItem("isClassAdded", true);
      document.body.classList.add("light-theme");
    }
  };

  const loadNotifications = async () => {
    // get notifications
    const notifications = await getNotifications();
    if (notifications && notifications?.data?.data) {
      setShowNotifications(true);
      setWebsiteNotifications(notifications?.data?.data);
    }
  };

  return (
    <>
      {(websiteNotifications.length && showNotifications && (
        <div className="marqu">
          <marquee>
            {websiteNotifications.map((notf, idx) => (
              <label style={{ marginRight: "30px" }} key={idx}>
                {notf?.content}
              </label>
            ))}
          </marquee>
          <span onClick={() => setShowNotifications(false)}>
            <AiOutlineClose />
          </span>
        </div>
      )) ||
        null}
      <header className="header afterlogin">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center">
                <Button
                  variant="primary menu-btn"
                  onClick={() => setShowMobileSideBar((p) => !p)}
                  className="d-block d-md-none"
                  id="menuSidebarHandler"
                >
                  <img
                    src={MenuIcon}
                    alt="User Menu Icon"
                    width={25}
                    id="menuSidebarHandlerImg"
                  />
                </Button>

                <div className="logo">
                  <a href="/sports">
                    <img src={appDetails?.LOGO_URL} alt="Logo" />
                  </a>
                </div>
                <div className="header-left ms-4 d-none d-lg-block">
                  <Navbar.Toggle
                    aria-controls="basic-navbar-nav"
                    className="d-none"
                  />
                  <Navbar.Collapse id="basic-navbar-nav" className="d-block">
                    <Nav className="me-auto">
                      {/* {<Nav.Link href="/home" className={activePage == "home" ? "active" : "">New Games</Nav.Link>} */}
                      <Nav.Link
                        href="/sportsbook/Inplay"
                        className={activePage == "sportsbook" ? "active" : ""}
                      >
                        Sportsbook
                      </Nav.Link>
                      <Nav.Link
                        href="/live-dealer"
                        className={activePage == "live-dealer" ? "active" : ""}
                      >
                        Live Dealers
                      </Nav.Link>
                      <Nav.Link
                        href="/casino/evolution"
                        className={activePage == "evolution" ? "active" : ""}
                      >
                        Evolution
                      </Nav.Link>
                      <Nav.Link
                        href="/casino/supernowa"
                        className={activePage == "supernowa" ? "active" : ""}
                      >
                        Supernowa
                      </Nav.Link>
                      <Nav.Link
                        href="/casino/spribe/aviator"
                        className={activePage == "aviator" ? "active" : ""}
                      >
                        Aviator
                      </Nav.Link>
                    </Nav>
                  </Navbar.Collapse>
                </div>

                <div className="headerRight ms-auto">
                  <ul>
                    <li className="balance">
                      {" "}
                      <div className="balance-amount">
                        <img
                          src={BalanceIcon}
                          alt="Balance Icon"
                          width={24}
                          onClick={() => navigate("/cashier")}
                        />
                        <span>{wallet?.balance}</span>
                        <a href="/gatewaylist" className="deposit-header">
                          <img
                            src={CashierIconNew}
                            alt="Balance Icon"
                            width={24}
                          />
                        </a>
                      </div>
                    </li>
                    <li>
                      <GamesSearchModal />
                    </li>
                    <li className="head-social">
                      <a
                        href={
                          appDetails?.TELEGRAM != ""
                            ? appDetails?.TELEGRAM
                            : "#"
                        }
                        target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                      >
                        <img src={TelegramIcon} alt="telegram" />
                      </a>
                    </li>

                    <li className="d-none">
                      <a
                        className="head-social"
                        href={
                          appDetails?.WHATS_APP_URL != ""
                            ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                            : "#"
                        }
                        target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                      >
                        <img src={WhatsappIcon} alt="whatsapp" />
                      </a>
                    </li>
                    {/* <div className="searchbar">
                    <AiOutlineSearch />
                  </div> */}
                  </ul>

                  <Button
                    variant="primary menu-btn"
                    onClick={handleShow}
                    className="d-none d-md-block"
                  >
                    <img src={MenuIcon} alt="User Menu Icon" width={25} />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Offcanvas
          className="user_section"
          show={show}
          onHide={handleClose}
          placement={"end"}
        >
          <Offcanvas.Header closeButton="closeButton" closeVariant="white">
            <Offcanvas.Title>
              <div className="d-flex">
                <div className="profile-img">
                  <img src={UserIcon} alt="User Icon" />
                </div>
                <div className="user-info">
                  <Link className="user_name" to="/profile">
                    {replaceUniqueString(user?.mstruserid)}
                  </Link>
                  <p>{user?.mobileno}</p>
                </div>
              </div>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="container upperbalnace">
              <div className="row">
                <div className="col-6">
                  <div className="balance mb-4">
                    <img src={WalletIcon} alt="wallet" />
                    Balance
                  </div>
                </div>
                <div className="col-6">
                  <div className="balance-amount mb-4">
                    {" "}
                    INR {wallet?.balance}
                  </div>
                </div>
                {/* <div className="col-12">
                <div className="non_cash_balance">
                  <h6 className="non_cash_balance_heading">
                    <span>
                      <strong> Bonus</strong> <small>Non-Cash-Balance </small>{" "}
                    </span>
                    <span>
                      <strong>{wallet?.coins}</strong>
                    </span>
                  </h6>
                </div>
              </div> */}
                <div
                  className="col-6"
                  onClick={() => {
                    navigate("/withdraw");
                  }}
                >
                  <Button variant="" className="btn_withdraw w-100">
                    Withdraw
                  </Button>
                </div>
                <div
                  className="col-6"
                  onClick={() => {
                    navigate("/gatewaylist");
                  }}
                >
                  <Button variant="primary" className="btn_primary w-100">
                    Deposit
                  </Button>
                </div>
              </div>
            </div>
            <ul className="menu-items">
              {/* <li>
              <a href="/profile">
                <FaRegUserCircle />
                <span>Profile</span>
              </a>
            </li> */}
              <li>
                <a href="/mybets">
                  <img src={BetIcon} alt="my bet" />
                  <span>My Bets</span>
                </a>
              </li>
              <li>
                <a href="/bonus" className="img_size">
                  <img src={BonusImg} />
                  <span className="bonus-space">Bonus</span>
                </a>
              </li>
              <li>
                <a href="/referral" className="img_size">
                  <img src={refferal} />

                  <span className="bonus-space">Referral</span>
                </a>
              </li>
              <li>
                <a href="/cashier">
                  <img src={AcStatement} alt="account statement" />
                  <span>Account Statement</span>
                </a>
              </li>

              <li onClick={() => dispatch(logoutUser())}>
                <a href="/">
                  {/* <LogoutIcon /> */}
                  <img src={LogoutIconImg} />
                  <span>Sign Out</span>
                </a>
              </li>
            </ul>

            <div className="theme-btn">
              <button type="button" className="btn" onClick={toggleClass}>
                <img src={MoonLight} alt="moon" className="dark_theme" />
                <img src={SunLight} alt="sun" className="light_theme" />
              </button>
            </div>
          </Offcanvas.Body>
        </Offcanvas>

        {showMobileSideBar && (
          <MobLeftbar
            className="d-block d-md-none"
            containerRef={containerRef}
          />
        )}
      </header>
    </>
  );
};

export default HeaderAfterLogin;
