export const appendUniqueString = (userName = "") => {
  return `7DAY_${userName}`;
};

export const replaceUniqueString = (userName = "") => {
  if (userName?.includes("7DAY_")) {
    return userName.split("7DAY_")?.[1];
  }
  return userName;
};
