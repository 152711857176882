import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { demoLogin, loginUser } from "../../../redux/auth/actions";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Button, Dropdown, Form } from "react-bootstrap";
import hide from "../../../assets/svg/hide.png";
// import LoginLeftPart from "../../../assets/images/modal.gif";
import EyeIcon from "../../../assets/svg/EyeIcon";
import { APP_CONST } from "../../../config/const";

import MumbaiSports from "../../../assets/images/icons/mumbaisports.png";
import { appendUniqueString } from "../../../utils/manageUniqueUser";
import { checkIfDemoUserExist } from "../../../redux/app/actions";

const LoginModal = ({ show, handleClose, handleModalShow }) => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const { appDetails } = useSelector((state) => state.app);
  const [isDemoUserExist, setIsDemoUserExist] = useState(false);

  useEffect(() => {
    checkDemoUser();
  }, []);

  const checkDemoUser = async () => {
    const response = await checkIfDemoUserExist();
    if (response && response?.data?.error === false) {
      setIsDemoUserExist(response?.data?.data?.hasDemoUsers === true);
    }
  };

  const validateData = () => {
    const { username, password } = formData;
    const newErrors = {};
    if (!username || username === "") {
      newErrors.username = "Username cannot be empty";
    }
    if (!password || password === "") {
      newErrors.password = "Password cannot be empty";
    }
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errorData = validateData();
    if (Object.keys(errorData).length > 0) {
      setErrors(errorData);
    } else {
      let loginData = {
        ...formData,
      };
      dispatch(loginUser(loginData));
    }
  };

  const handleChange = (e) => {
    let fieldName = e.target.name;
    let fieldValue = e.target.value;
    let tempFormData = { ...formData, [fieldName]: fieldValue };
    setFormData(tempFormData);
  };

  const handleDemoLogin = () => {
    dispatch(demoLogin());
  };

  return (
    <>
      <Modal
        show={show}
        className={"steps-canvas login-modal"}
        onHide={() => {
          console.log("working");
        }}
        placement={"end"}
      >
        <Modal.Header
          closeButton
          onClick={() => handleClose(APP_CONST.AUTH_MODAL.LOGIN)}
          closeVariant="black"
        >
          <Modal.Title style={{ color: "#013862" }}>Sign In</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: "#000a2c" }}>
          <div className="login-section">
            {/* <div className="login-leftPart d-none">
            <img src={LoginLeftPart} className="signin" />
          </div> */}
            <div className="Login-rightPart">
              {/* <Modal.Title>Sign in now!</Modal.Title> */}
              <div className="form-steps">
                <h2 className="h2">Welcome to</h2>
                <img
                  className="login-modal-img"
                  src={appDetails?.LOGO_URL}
                  alt="Logo"
                />

                {/* <p>Welcome to 7dayexch</p> */}
                <Form>
                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Control
                      type="text"
                      placeholder="Enter Username*"
                      name="username"
                      onChange={handleChange}
                      isInvalid={!!errors.username}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors?.username}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    className="mb-3 position-relative"
                    controlId="formBasicPassword"
                  >
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      placeholder="Password*"
                      name="password"
                      onChange={handleChange}
                      isInvalid={!!errors.password}
                    />
                    <span
                      className="icon"
                      onClick={() => setShowPassword((s) => !s)}
                    >
                      {showPassword ? <img src={hide} alt="" /> : <EyeIcon />}
                    </span>
                    <Form.Control.Feedback type="invalid">
                      {errors?.password}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <div className="d-flex justify-content-end mb-3">
                    <a
                      className="form-link"
                      href="#"
                      onClick={() => {
                        handleClose(
                          APP_CONST.AUTH_MODAL.FORGOTO_PASSWORD,
                          "auto"
                        );
                        handleModalShow(APP_CONST.AUTH_MODAL.FORGOTO_PASSWORD);
                      }}
                    >
                      Password Recovery
                    </a>
                  </div>
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Sign in
                  </Button>
                  {(isDemoUserExist && (
                    <Button
                      variant="primary"
                      onClick={handleDemoLogin}
                      style={{ marginLeft: "2px" }}
                    >
                      Login with Demo Id
                    </Button>
                  )) ||
                    null}
                </Form>

                <div className="sign-up mt-4">
                  Not a member?
                  <br />
                  <a
                    href="#"
                    onClick={() => {
                      handleClose(APP_CONST.AUTH_MODAL.LOGIN, "auto");
                      handleModalShow(APP_CONST.AUTH_MODAL.MOBILE_NUMBER);
                    }}
                  >
                    Sign Up Now & Claim bonus!
                  </a>
                </div>

                <div className="sportsSection d-none">
                  <img src={MumbaiSports} />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {show && <div className="modal-overlay"></div>}
    </>
  );
};
export default LoginModal;
